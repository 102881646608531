import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editUser(ctx, {id, userData}) {
      return new Promise((resolve, reject) => {
        const data = JSON.parse(JSON.stringify(userData));
        if(typeof data.organization != 'string'){
          data.organization = data.organization.id;
        }
        axios
          .patch(`/users/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    generateRegisterToken(ctx, userData){
      return new Promise((resolve, reject) => {
        const data = JSON.parse(JSON.stringify(userData));
        if(typeof data.organization != 'string'){
          data.organization = data.organization.id;
        }
        axios
          .post(`/auth/generate-register-token`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
