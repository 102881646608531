<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

 
      <user-edit-tab-account
        v-if="userData"
        :user-data="userData"
        :operation-types-options="allOperationTypes"
        :organizations-data="organizationsData"
        class="mt-2 pt-75"
      />
  </component>
</template>

<script>
import {
   BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import organizationStoreModule from '../../organization/organizationStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    UserEditTabAccount
  },
  setup() {
    const userData = ref(null)
    const organizationsData = ref([])
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const ORGANIZATION_APP_STORE_MODULE_NAME = 'app-organization'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.registerModule(ORGANIZATION_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { 
        userData.value = response.data;
        if(userData.value.role === 'SUPERADMIN'){
          store
            .dispatch('app-organization/fetchOrganizations', {selectedFields: "id name", limit: 1000}).then(response => {
              organizationsData.value = response.data.results.map(x => {
                return {
                  label: x.name,
                  value: x.id
                }
              });
            })
        } 
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    const allOperationTypes = [
      { label: 'All', value: 'ALL' },
      { label: 'Image search', value: 'IMAGE_SEARCH' },
      { label: 'Image editor', value: 'IMAGE_EDITOR' },
      { label: 'Contilt scheme', value: 'CONTILT_SCHEME' },
    ]
    return {
      userData,
      allOperationTypes,
      organizationsData
    }
  },
}
</script>

<style>

</style>
